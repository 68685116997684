import React from "react"
import { graphql } from "gatsby"

// import { StoreDetails as Template } from "../components/Store/StoreDetails"

export const query = graphql`
  query($id: String!) {
    store: sanityStore(_id: { eq: $id }) {
      ...GatsbyStoreFragment
    }
    template: sanityTemplateStore {
      settingShowSocialShare
      settingShowRelated
      additionalShareText
      additionalRelatedText
    }
  }
`

export default ({ data, ...props }): JSX.Element => null // <Template {...props} {...data} />
